<template>
  <div>
    <div class="alignment-page">
      <button class="button is-info is-small" @click="visible = !visible">
        <span v-show="!visible">Show</span>
        <span v-show="visible">Hide</span>
        &nbsp;
        <span>Grid</span>
      </button>
    </div>
    <client-only>
      <div v-if="visible" class="alignment-helper">
        <DefaultSection>
          <Container>
            <div v-if="windowHeight" class="columns">
              <div v-for="(n, index) in 12" :key="index" class="column">
                <div
                  v-for="(m, innerIndex) in Math.floor(windowHeight / 20)"
                  :key="innerIndex"
                  class="cell"
                />
              </div>
            </div>
          </Container>
        </DefaultSection>
      </div>
    </client-only>
  </div>
</template>

<script setup lang="ts">
const windowHeight = ref(20)
const visible = ref(false)

onMounted(() => {
  windowHeight.value = document.documentElement.scrollHeight
  window.addEventListener('resize', () => {
    windowHeight.value = document.documentElement.scrollHeight
  })
})
</script>

<style lang="scss">
.alignment-page {
  position: absolute;
  > .button {
    position: sticky;
    top: 0;
    z-index: 10000;
  }
}

.alignment-helper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1000;

  .container {
    height: 100%;
    padding-top: 0;
    padding-bottom: 0;
  }

  .section {
    height: 100%;
    padding-top: 0;
    padding-bottom: 0;
  }
  .columns {
    height: 100%;
    .column {
      > div {
        height: 100%;
      }
      .cell {
        height: 20px;
        background: rgba($grey, 0.3);

        &:nth-child(2n + 1) {
          background: rgba($grey, 0.1);
        }
      }
    }
  }
}
</style>
