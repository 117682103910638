<template>
  <section
    class="menu-container has-background-primary section"
    :class="{ open }"
  >
    <nav role="menu" aria-label="main navigation" class="navbar main-nav section is-primary is-size-5 is-size-3-touch has-text-weight-bold is-fixed-top">
      <div class="container">
        <div class="navbar-brand">
          <nuxt-link aria-label="Home" class="navbar-item py-0" to="/">
            <Logo class="image" />
          </nuxt-link>
          <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" :class="{ 'is-active': open }" @click="open = !open">
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </a>
        </div>
        <div class="navbar-menu" :class="{ 'is-active': open }">
          <div class="navbar-start">
            <template v-for="(item, index) in menuActive.links">
              <div
                v-if="item.links.length"
                :key="`xx_${index}`"
                :class="{
                  'is-in-trail': item.active,
                  'is-active': hover === index,
                }"
                class="navbar-item has-dropdown has-text-centered"
              >
                <DefaultLink
                  :to="item.url.path"
                  class="navbar-link"
                  @click.capture.prevent="
                    onFirstLevelClick($event, index, item.links.length, item.url.path)
                  "
                >
                  {{ item.label }}
                </DefaultLink>
                <div ref="dropdown" class="navbar-dropdown">
                  <div class="is-hidden-touch menu-cover">
                    <img alt="Nach oben" src="~/assets/svgs/up.svg?url" data-not-lazy>
                  </div>

                  <DefaultLink
                    v-for="(subitem, subindex) in item.links"
                    :key="subindex"
                    :to="subitem.url.path"
                    :open-in-same-window="componentTag(subitem.url.path) === 'a'"
                    class="navbar-item is-block has-text-primary has-text-centered is-size-5"
                    @click="closeDropdown"
                  >
                    {{ subitem.label }}
                  </DefaultLink>
                </div>
              </div>
              <DefaultLink v-else :key="index" :to="item.url.path">
                {{ item.label }}
              </DefaultLink>
            </template>
          </div>
          <div class="navbar-end">
            <div class="navbar-item">
              <MenuButton title="App downloaden" />
            </div>

            <div class="navbar-item">
              <div class="has-text-centered">
                <nuxt-link
                  :to="{ name: 'user-login' }"
                  class="navbar-link is-arrowless login"
                >
                  Login
                </nuxt-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </section>
</template>

<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'
import { useDefaultStore } from '~/stores/defaultStore'
import Logo from '~/assets/svgs/lessmess-logo.svg?component'

const props = defineProps({
  menu: {
    type: Object,
    default: () => {
      return { items: [] }
    },
  },
})

defineEmits(['close'])
const open = ref(false)
const hover = ref(-1)

const defaultStore = useDefaultStore()

const isActive = (item, path) => item.url && item.url.path === path

function setActive(menu, path) {
  const newMenu = {
    ...menu,
  }
  let subActive = false

  if (menu.links && menu.links.length > 0) {
    newMenu.links = []
    menu.links.forEach((item) => {
      const { active, menu } = setActive(item, path)
      if (active) {
        subActive = true
      }
      newMenu.links.push(menu)
    })
  }

  newMenu.active = subActive || isActive(menu, path)

  return {
    active: newMenu.active,
    menu: newMenu,
  }
}

const route = useRoute()

const menuActive = computed(() => setActive(props.menu, route.path).menu)

function onFirstLevelClick(
  e: MouseEvent,
  index: number,
  hasChildren: boolean,
  path?: string,
) {
  if (!hasChildren) {
    if (path) {
      router.push(path)
    }
    emit('close')
    hover.value = -1
    return
  }
  e.stopPropagation()
  e.preventDefault()
  setActiveItem(index, true)
}

function setActiveItem(index: number, toggle?: boolean) {
  // const hasChildren = !!linksMapped.value[index]?.children.length
  // if (!hasChildren) {
  //   hover.value = -1
  //   return
  // }
  // hasTransition.value = index === -1 || hover.value === -1
  // Behavior when toggling is requested.
  if (toggle) {
    hover.value = hover.value === index ? -1 : index
    return
  }
  hover.value = index
}

const dropdown = ref(null)
onClickOutside(dropdown, () => {
  hover.value = -1
})

const closeDropdown = () => {
  hover.value = -1
}

function componentTag(url) {
  let tag = 'a'
  let absolute = false

  if (
    url
    && (url.indexOf('http://') === 0 || url.indexOf('https://') === 0)
  ) {
    absolute = true
  }

  if (url && !absolute) {
    tag = 'nuxt-link'
  }
  return tag
}

function onResize() {
  const isMobile = window.innerWidth < 990
  if (isMobile !== props.isMobile) {
    defaultStore.setIsMobile(isMobile)
  }
}

onMounted(() => {
  window.addEventListener('resize', onResize)
  onResize()
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize)
})
</script>

<style lang="scss">
.main-nav {
  .navbar-brand {
    margin-left: 0 !important;

    .navbar-item {
      padding-left: 0;
    }

    svg {
      width: toRem(168);
      height: toRem(52);
    }

    a {
      background-color: transparent !important;
    }
  }

  .navbar-start {
    margin-left: auto;
    margin-right: 0;
  }

  .navbar-menu {
    margin-right: 0 !important;
    margin-left: -2rem;

    @include desktop {
      margin-left: 0;
    }
  }

  .navbar-end {
    margin-left: 0;

    .navbar-item {
      padding-right: 0;
    }

    .button.is-lessmess-app {
      @include desktop {
        margin-left: 1rem;
      }
    }

    .login {
      padding-left: 0;
      @include desktop {
        margin-left: 2rem;
      }
    }
  }

  a.navbar-item,
  a.navbar-link {
    background-color: transparent !important;
  }

  a.navbar-item {
    padding-left: 1.9rem;
    padding-right: 1.9rem;
  }

  a.navbar-link {
    padding-left: 2rem;
  }

  .menu-cover {
    position: relative;
    top: toRem(-52);
    height: 0;
  }

  .navbar-dropdown {
    border: none;

    a.navbar-item {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }
  }

  .navbar-link:not(.is-arrowless)::after {
    width: 0.6rem;
    height: 0.6rem;
  }

  @include desktop {
    .navbar-dropdown {
      margin-top: -1rem;
      padding-top: toRem(35);
      padding-bottom: toRem(20);
      border-radius: 3px;
      left: 50%;
      transform: translateX(-50%);

      .navbar-item {
        margin-bottom: toRem(10);
      }
    }

    .navbar-start {
      .navbar-item.has-dropdown:hover {
      }
      .navbar-link {
        background-color: transparent !important;
      }
    }
  }
}

@include touch {
  .menu-container.open {
    background-color: $sky-blue-18 !important;

    .navbar {
      background-color: $sky-blue-18 !important;

      .navbar-brand svg path {
        fill: $primary !important;
      }

      .navbar-burger,
      .navbar-item,
      .navbar-link {
        color: $primary;
      }

      .navbar-item {
        text-align: center;
      }

      .navbar-dropdown {
        a {
          font-weight: 600;
        }
      }

      .navbar-link:not(.is-arrowless) {
        padding-right: 0;
        padding-left: 0;

        &:after {
          display: none;
        }
      }

      .navbar-item.has-dropdown {
        margin-bottom: 2rem;
      }

      .navbar-start {
        margin-top: 2rem;

        @include tablet {
          margin-top: 3rem;
        }

        > .navbar-item {
          margin-bottom: 1rem;
        }
      }

      .navbar-end {
        //position: absolute;
        //top: 100vh;
        //transform: translateY(-120%);
        //width: 100%;
      }
    }
  }

  .navbar-menu.is-active {
    height: 100vh;
    background-color: $sky-blue-18 !important;
    box-shadow: none;
  }
}
</style>
