<template>
  <div v-if="defaultStore.footerMenu" class="footer-navigation-right">
    <DefaultLink
      v-for="(link, index) in defaultStore.footerMenu.links"
      :key="index"
      :to="link.url.path ? link.url.path : null"
      class="is-block has-text-weight-semibold has-text-primary is-size-normal mb-5"
    >
      {{ link.label }}
    </DefaultLink>
  </div>
</template>

<script  setup lang="ts">
import { useDefaultStore } from '~/stores/defaultStore'

const defaultStore = useDefaultStore()
</script>

<style scoped lang="scss">
.footer-navigation-right {
  column-count: 2;
  column-gap: 30px;

  a:nth-child(4n) {
    margin-bottom: 0 !important;
  }
}
</style>
